<template>
  <Ticketist />
</template>

<script>
import Ticketist from "../components/TicketDetail";

export default {
  name: "ticketus",

  components: {
    // eslint-disable-next-line vue/no-unused-components
    Ticketist,
  },
};
</script>
