<!-- ライブ情報 -->
<template>
  <v-dialog v-model="dialogOpenFlg" persistent max-width="500px">
    <v-card class="pa-4 mx-auto childHeight">
      <v-card-title
        ><span class="text-center">チケット予約</span>
      </v-card-title>
      <v-card-text v-if="ticketInfo">
        <p class="my-0">タイトル :{{ liveDetailList.title }}</p>
        <p class="my-0">ライブハウス :{{ liveDetailList.live_house_name }}</p>
        <p class="my-0">日時 :{{ convertDate(liveDetailList.live_date) }}</p>
        <p class="my-0">
          チケット料金 : ¥{{ liveDetailList.adv_price }} + ドリンク代
        </p>
        <p class="my-0">
          開場時刻 :{{ time_format(liveDetailList.open_time) }}
        </p>
        <p class="my-0">
          開演時刻 :{{ time_format(liveDetailList.start_time) }}
        </p>
      </v-card-text>
      <div v-if="dialog_1" class="childHeight">
        <v-card-text>
          <v-col cols="12" class="py-0 my-0">
            <v-text-field
              label="名前(代表者名)"
              required
              v-model="name"
              ref="name"
              counter="20"
              :rules="[required, limit_length]"
              hint="2名以上の場合、代表者名を入力してください。"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="py-0 my-0">
            <v-text-field
              label="メールアドレス"
              v-model="email"
              ref="email"
              :rules="[required]"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="py-0 my-0">
            <v-text-field
              type="number"
              label="チケット枚数"
              suffix="枚"
              ref="ticket"
              v-model="ticket"
              :rules="ticket_limits"
              required
            ></v-text-field>
          </v-col>
        </v-card-text>
        <v-card-actions class="mx-1">
          <v-btn text @click="modalClose"> 閉じる </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="submitFlg"
            elevation="0"
            color="primary"
            v-on:click="submit"
          >
            確認
          </v-btn>
        </v-card-actions>
      </div>
      <div v-if="dialog_2" class="childHeight">
        <v-card-text class="mx-2">
          <p class="my-0">名前 : {{ name }}</p>
          <p class="my-0">メールアドレス : {{ email }}</p>
          <p class="my-0">チケット枚数 : {{ ticket }} 枚</p>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-text class="mx-2">
          <p class="my-0">以上の内容でよろしければ予約ボタンを押してください</p>
        </v-card-text>
        <v-card-actions>
          <v-btn text v-on:click="returnDialog"> 戻る </v-btn>
          <v-spacer></v-spacer>
          <v-btn elevation="0" color="primary" v-on:click="bookingTicket">
            予約
          </v-btn>
        </v-card-actions>
      </div>
      <div v-if="dialog_3" class="heightLoad">
        <v-row class="childHeight">
          <v-col align="center" align-self="center">
            <v-progress-circular
              :size="70"
              :width="7"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-col>
        </v-row>
      </div>
      <div v-if="dialog_4" class="heightLoad">
        <v-row class="childHeight">
          <v-col align="center" align-self="center">
            <v-icon :color="resultObj.icon_color" x-large>{{
              resultObj.icon_name
            }}</v-icon>
            <v-card-text style="white-space: pre-line">
              <p>{{ resultObj.title }}</p>
              <p>{{ resultObj.message }}</p>
              <p class="text-size-sm">{{ resultObj.sub_message }}</p>
            </v-card-text>
            <v-row>
              <v-col align="center" align-self="center">
                <v-btn
                  v-if="resultObj.status == 0"
                  elevation="0"
                  color="primary"
                  v-on:click="closeDialog"
                >
                  閉じる
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "BookingTickets",
  data: () => ({
    model: null,
    dayList: ["日", "月", "火", "水", "木", "金", "土"],
    name: "",
    email: "",
    ticket: 1,
    dialog_1: true,
    dialog_2: false,
    dialog_3: false,
    dialog_4: false,
    ticketInfo: true,
    // 確認ボタン活性/非活性の操作に使用
    nameForm: false,
    emailForm: false,
    ticketForm: false,
    // バリデーションチェック
    required: (value) => !!value != 0 || "必ず入力してください",
    limit_length: (value) =>
      value.length <= 20 || "20文字以内で入力してください",
    ticket_limits: [
      (value) => value <= 10 || "チケットは10枚まで取り置き可能です",
    ],
    emailRules: (v) =>
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) || "正しいメールアドレスを入力してください",
    //登録完了or失敗画面に表示するもの
    resultObj: {
      icon_name: "",
      icon_color: "",
      title: "",
      status: 0,
      message: "",
      sub_message: "",
    },
  }),
  props: {
    dialogOpen: { type: Boolean, default: false },
    liveDetailList: { type: Object },
  },
  watch: {
    // 名前入力欄チェック
    name: function () {
      if (this.$refs["name"].validations.length == 0) {
        this.nameForm = true;
      } else {
        this.nameForm = false;
      }
    },
    // メールアドレス入力欄チェック
    email: function () {
      if (this.$refs["email"].validations.length == 0) {
        this.emailForm = true;
      } else {
        this.emailForm = false;
      }
    },
    ticket: function () {
      if (this.$refs["ticket"].validations.length == 0) {
        this.ticketForm = true;
      } else {
        this.ticketForm = false;
      }
    },
  },
  computed: {
    // ダイアログ開閉フラグ
    dialogOpenFlg: function () {
      return this.dialogOpen;
    },
    // すべての入力欄チェックが完了すると活性化
    submitFlg() {
      let res = true;
      if (this.name.length > 0 && this.email.length > 0) {
        res = false;
      }
      return res;
    },
  },
  created: function () {
    this.axios
      .get(process.env.VUE_APP_API_URL_TICKET + "get-token/")
      .then(() => {})
      .catch((e) => {
        console.log(e);
      });
  },
  methods: {
    modalClose() {
      this.$emit("is-close", false);
    },
    goGoogleMap(address) {
      let url = "https://maps.google.co.jp/maps/search/";
      let address_url = url + address;
      window.open(address_url, "_blank");
    },
    convertDate(date) {
      let dateObj = new Date(date);
      let dateYear = dateObj.getFullYear();
      let dateMonth = dateObj.getMonth() + 1;
      let dateDate = dateObj.getDate();
      let dateDay = dateObj.getDay();
      let newDate =
        dateYear +
        "年" +
        dateMonth +
        "月" +
        dateDate +
        "日(" +
        this.dayList[dateDay] +
        ")";
      return newDate;
    },
    time_format(time) {
      let res = time.slice(0, 5);
      return res;
    },
    submit() {
      // 2枚目のダイアログを表示する
      this.dialog_1 = false;
      this.dialog_2 = true;
    },
    returnDialog() {
      // 1枚目のダイアログに戻る
      this.dialog_2 = false;
      this.dialog_1 = true;
    },
    bookingTicket() {
      this.dialog_1 = false;
      this.dialog_2 = false;
      this.ticketInfo = false;
      this.dialog_3 = true;
      //ライブ情報をDBに登録する
      this.axios.defaults.xsrfCookieName = "csrftoken"; // ←ココと
      this.axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
      this.axios
        .post(process.env.VUE_APP_API_URL + "ticket/", {
          live_id: this.liveDetailList["key"],
          name: this.name,
          email_address: this.email,
          tickets: this.ticket,
          title: this.liveDetailList["title"],
          live_date: this.liveDetailList["live_date"],
          live_house_name: this.liveDetailList["live_house_name"],
        })
        .then(() => {
          //成功した場合確認用メールを送信
          this.sendEmail(this.liveDetailList);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    sendEmail: function (liveDetailList) {
      let sendObj = {
        name: this.name,
        email_address: this.email,
        tickets: this.ticket,
        title: liveDetailList["title"],
        live_date: this.convertDate(liveDetailList["live_date"]),
        live_house_name: liveDetailList["live_house_name"],
        live_address: liveDetailList["address"],
        open_time: this.time_format(liveDetailList["open_time"]),
        start_time: this.time_format(liveDetailList["start_time"]),
        adv_price: liveDetailList["adv_price"],
      };
      this.axios.defaults.xsrfCookieName = "csrftoken"; // ←ココと
      this.axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
      this.axios
        .post(process.env.VUE_APP_API_URL_TICKET + "send_email/", { sendObj })
        .then((response) => {
          this.resultObj = response.data;
          setTimeout(this.enable, 1000);
        })
        .catch(() => {
          this.resultObj = {
            icon_name: "fa-xmark",
            icon_color: "error",
            title: "Error",
            status: 0,
            message: "不明なエラーが発生しました。",
            sub_message:
              "このエラー発生はマジやばいので公式TwitterにDMください",
          };
        });
    },
    enable: function () {
      this.dialog_3 = false;
      this.dialog_4 = true;
    },
    closeDialog: function () {
      // ダイアログを閉じる
      this.$emit("is-close", false);
      // 各ダイアログ表示フラグを初期値にする。
      this.dialog_1 = true;
      this.ticketInfo = true;
      this.dialog_2 = false;
      this.dialog_3 = false;
      this.dialog_4 = false;
      // フォームに入力された値を初期値にする
      this.name = "";
      this.email = "";
      this.ticket = 0;
    },
  },
};
</script>
<style>
.paddingLive {
  padding: 0 20%;
}
.childHeight {
  height: 100%;
}
.heightLoad {
  height: 400px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.fade2-enter-active,
.fade2-leave-active {
  transition: opacity 0.3s;
}
.fade2-enter, .fade2-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
