<template>
  <Member />
</template>

<script>
import Member from "../components/Profile";

export default {
  name: "news",

  components: {
    // eslint-disable-next-line vue/no-unused-components
    Member,
  },
};
</script>
