<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <transition name="fade">
      <div v-show="loading" :class="logo_margin">
        <v-row align-content="center">
          <v-col align="center">
            <v-sheet
              color="transparent"
              :height="logo_margin_size"
              :width="logo_margin_size"
            >
              <LottieAnimation
                ref="anime"
                :animationData="require('@/assets/json/curio_logo.json')"
                :loop="true"
                :autoPlay="true"
              />
            </v-sheet>
          </v-col>
        </v-row>
      </div>
    </transition>
    <transition name="main_fade">
      <div v-show="!loading">
        <v-app-bar
          v-if="$vuetify.breakpoint.xs"
          :style="{ background: $vuetify.theme.themes.light.background }"
          clipped-left
          app
          extended
          color="grey lighten-2"
          class="mx-0 px-0 pt-5"
          elevation="0"
        >
          <!-- スマホ画面 -->
          <v-col cols="4" class="mx-0 px-0">
            <v-app-bar-nav-icon
              class="ml-0 pl-0"
              @click.stop="drawer = !drawer"
            ></v-app-bar-nav-icon>
            <v-navigation-drawer
              v-model="drawer"
              app
              disable-route-watcher
              disable-resize-watcher
              hide-overlay
              elevation="0"
              color="grey lighten-2"
            >
              <v-list class="margin-top" shaped>
                <v-list-item-group
                  color="primary"
                  v-for="(item, index) in pageList"
                  :key="index"
                >
                  <v-list-item
                    @click.stop="drawer = !drawer"
                    v-show="item.show"
                    :to="item.path"
                  >
                    <v-list-item-title class="text-size-sm">{{
                      item.name
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <template v-slot:append>
                <v-card color="grey lighten-2" flat tile width="100%">
                  <v-card-text>
                    <v-btn
                      v-for="item in icons"
                      :key="item.icon"
                      :href="item.url"
                      icon
                    >
                      <v-icon size="20px">
                        fa-brands fa-{{ item.icon }}
                      </v-icon>
                    </v-btn>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-text class="ml-3 text-size-sm">
                    © {{ new Date().getFullYear() }} Curiosity.
                  </v-card-text>
                </v-card>
              </template>
            </v-navigation-drawer>
          </v-col>
          <v-col class="">
            <router-link to="/">
              <v-img
                alt="Logo"
                class="shrink"
                contain
                src="@/assets/images/logo_mark.svg"
                width="90"
              />
            </router-link>
          </v-col>
        </v-app-bar>
        <!-- デスクトップ画面 -->
        <v-app-bar
          v-else
          app
          :style="{ background: $vuetify.theme.themes.light.background }"
          color="grey lighten-2 pt-5"
          class="padding"
          extended
          elevation="0"
        >
          <router-link to="/">
            <v-img
              alt="Logo"
              class="shrink mr-8"
              contain
              src="@/assets/images/logo_mark.svg"
              width="90"
            />
          </router-link>
          <div v-for="(item, index) in pageList" :key="index">
            <router-link
              color=""
              class="routerlink"
              :to="item.path"
              v-show="item.show"
              ><span class="mr-10 mb-6 text-size-sm">{{
                item.name
              }}</span></router-link
            >
          </div>
        </v-app-bar>
        <v-img
          :height="cardHeight"
          class="position-relative margin-top-image"
          gradient="to top right, rgba(0,0,8,0.6629245448179272), rgba(0,0,0,0.10270045518207283)"
          :src="this.artistPhotoUrl"
          position="top"
          v-if="this.$router.currentRoute.name == 'HOME'"
        >
          <v-row justify="center">
            <v-col class="text-center" cols="12">
              <div>
                <v-img
                  class="mx-auto position-absolute"
                  width="60%"
                  contain
                  src="@/assets/images/logo_type.svg"
                ></v-img>
              </div>
            </v-col>
          </v-row>
        </v-img>

        <v-main :class="sideMargin">
          <router-view />
        </v-main>

        <v-footer height="150px" color="transparent">
          <v-card
            color="transparent"
            flat
            tile
            width="100%"
            class="grey lighten-2 text-center"
          >
            <v-card-text>
              <v-btn
                v-for="item in icons"
                :key="item.icon"
                :href="item.url"
                class="mx-4"
                icon
              >
                <v-icon size="24px"> fa-brands fa-{{ item.icon }} </v-icon>
              </v-btn>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-text>
              © {{ new Date().getFullYear() }} Curiosity.
            </v-card-text>
          </v-card>
        </v-footer>
      </div>
    </transition>
  </v-app>
</template>

<script>
import LottieAnimation from "lottie-web-vue";
export default {
  name: "App",
  components: {
    LottieAnimation,
  },
  computed: {
    margin: function () {
      let val = "mr-2";
      if (this.$vuetify.breakpoint.xs) {
        val = "margin";
      }
      return val;
    },
    logo_margin: function () {
      let val = "mt-original";
      if (this.$vuetify.breakpoint.xs) {
        val = "mt-original-sm";
      }
      return val;
    },
    logo_margin_size: function () {
      let val = "20%";
      if (this.$vuetify.breakpoint.xs) {
        val = "30%";
      }
      return val;
    },
    cardHeight: function () {
      let val = 750;
      if (this.$vuetify.breakpoint.xs) {
        val = 300;
      }
      return val;
    },
    artistPhotoUrl: function () {
      return this.$store.state.artistPhotoUrl;
    },
    sideMargin: function () {
      let val = "margin-x";
      if (this.$vuetify.breakpoint.xs) {
        val = "";
      }
      return val;
    },
  },
  data: () => ({
    icons: [
      {
        icon: "youtube",
        url: "https://www.youtube.com/channel/UCKUDk2-9iOtN5LZFVnsAPdA",
      },
      { icon: "twitter", url: "https://twitter.com/curioband" },
      {
        icon: "instagram",
        url: "https://www.instagram.com/curioband/",
      },
    ],
    pageList: [],
    drawer: false,
    loading: true,
  }),
  created: function () {
    this.pageList = this.$router.options.routes;
    console.log(this.$router);
    if (this.artistPhotoUrl == "") {
      this.getAritstPhoto();
    }
  },
  mounted: function () {
    setTimeout(this.loaded, 3000);
  },
  methods: {
    loaded() {
      this.loading = false;
    },
    getAritstPhoto() {
      this.axios
        .get(process.env.VUE_APP_API_URL + "photo")
        .then((response) => {
          this.$store.dispatch("setArtistPhotoUrl", {
            artistPhotoUrl: response.data[0]["image"],
          });
        })
        .catch((e) => {
          alert(e);
        });
    },
    // setMeta(route) {
    //   // タイトルを設定
    //   if (route.meta.title) {
    //     let setTitle = route.meta.title;
    //     document.title = setTitle;
    //   }
    //   // ディスクリプションを設定
    //   if (route.meta.desc) {
    //     let setDesc = route.meta.desc;
    //     document
    //       .querySelector("meta[name='description']")
    //       .setAttribute("content", setDesc);
    //   }
    // },
  },
};
</script>
<style>
.v-application {
  font-family: "yu-gothic-pr6n", sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.12em;
}
.routerlink {
  color: #424242 !important;
  text-decoration: none !important;
}
.routerlink :hover {
  text-decoration: underline !important;
}
.z-index {
  z-index: 100 !important;
}
.margin {
  margin-left: 120%;
}
.padding {
  padding: 0 10%;
}
.border {
  border: #424242;
}
.z-index {
  z-index: 999;
}
.margin-top {
  padding-top: 50%;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.main_fade-enter-active,
.main_fade-leave-active {
  transition: opacity 2s;
}
.main_fade-enter, .main_fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.mt-original {
  margin-top: 15%;
}
.mt-original-sm {
  margin-top: 65%;
}
.margin-x {
  margin: 0 28% 0 28%;
}
.margin-top-image {
  margin-top: 112px;
}
</style>
