<template>
  <Contact />
</template>
<script>
import Contact from "../components/Contact";

export default {
  name: "contact",

  components: {
    // eslint-disable-next-line vue/no-unused-components
    Contact,
  },
};
</script>
