<!-- ライブ情報 -->
<template>
  <v-container>
    <v-row class="text-center ma-3">
      <v-col cols="12">
        <span class="text-size-xl">CONTACT</span>
      </v-col>
    </v-row>
    <v-row class="text-center mt-10 mx-10 pb-0 mb-0">
      <v-col cols="12" class="text-left">
        <p>
          ライブ・イベントの出演依頼、メディア取材等、各種お問い合わせにつきまてしてはTwiiterのダイレクトメッセージよりご連絡をお願いします。
        </p>
      </v-col>
    </v-row>
    <v-row justify="center" class="mx-12">
      <v-btn
        href="https://twitter.com/messages/compose?recipient_id=965108334683549696"
        target="_blank"
        color="#1DA1F2"
        class="white--text my-0 py-0"
        style="text-transform: none"
        :width="buttonWidth"
        elevation="0"
        ><v-icon left color="white">fa-brands fa-twitter</v-icon
        ><span class="text-bold">Twitterを開く</span></v-btn
      >
    </v-row>
    <v-row class="text-center ma-10">
      <v-col cols="12" class="text-left">
        <p>
          ※<a href="mailto:curiosity.band.0428@gmail.com"
            >curiosity.band.0428@gmail.com</a
          >からでもお問い合わせいただけます。
        </p>
        <p class="mb-0 pb-0">
          ご返信までにはお時間をいただく場合がございます。
        </p>
        <p class="mb-0 pb-0">
          なお、全てのお問い合わせへのご返信をお約束するものではございません。
        </p>
        <p>あらかじめご了承ください。</p>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "CONTACT",
  props: {
    topPage: { type: Boolean, default: false },
  },
  data: () => ({
    model: null,
    dayList: ["日", "月", "火", "水", "木", "金", "土"],
    dialog: false,
  }),
  computed: {
    textSize: function () {
      let val = "text-subtitle-1";
      if (this.$vuetify.breakpoint.xs) {
        val = "text-body-1";
      }
      return val;
    },
    padding: function () {
      let val = "pt-5";
      if (this.$vuetify.breakpoint.xs) {
        val = "pt-4";
      }
      return val;
    },
    paddingList: function () {
      let val = "px-12";
      if (this.$vuetify.breakpoint.xs) {
        val = "blank";
      }
      return val;
    },
    cardWidth: function () {
      let val = 380;
      if (this.$vuetify.breakpoint.xs) {
        val = 220;
      }
      return val;
    },
    newsLists: function () {
      return this.$store.state.newsLists;
    },
    buttonWidth: function () {
      let val = "";
      if (this.$vuetify.breakpoint.xs) {
        val = "100%";
      }
      return val;
    },
  },
  created: function () {
    if (this.newsLists.length == 0) {
      this.getNewsList();
    }
  },
  methods: {
    getNewsList() {
      this.axios
        .get(process.env.VUE_APP_API_URL + "news")
        .then((response) => {
          this.$store.dispatch("setNewsList", { newsLists: response.data });
        })
        .catch((e) => {
          alert(e);
        });
    },
    convertDate(date) {
      let dateObj = new Date(date);
      let dateYear = dateObj.getFullYear();
      let dateMonth = dateObj.getMonth() + 1;
      let dateDate = dateObj.getDate();
      let newDate = dateYear + "." + dateMonth + "." + dateDate;
      return newDate;
    },
    test() {
      return;
    },
    showMoreInformation(id) {
      this.$router.push({
        name: "newsDetail",
        params: { id: id },
      });
    },
  },
};
</script>
<style>
.paddingNewsList {
  padding: 0 11%;
}
.paddingNewsTopList {
  padding: 0 5%;
}
</style>
