<!-- ライブ情報 -->
<template>
  <v-container>
    <v-row class="ma-3">
      <v-col cols="12">
        <span :class="title_margin" class="text-size-xxl text-bold title-font"
          >LIVE</span
        >
      </v-col>
    </v-row>
    <div v-if="!topPage">
      <v-row class="paddingLiveList">
        <v-col
          :id="data.id + index"
          v-for="(data, index) in livelists"
          :key="'first-' + index"
          cols="12"
        >
          <v-card
            color="transparent"
            elevation="0"
            tile
            class="mx-2"
            @click.native="showMoreInformation(data.key)"
          >
            <v-divider class=""></v-divider>
            <v-card-title class="text-bold mb-0 pb-0 mt-4">{{
              convert_romaji(data.address)
            }}</v-card-title>
            <v-card-text class="mt-0 pt-0">
              <div class="text--primary">{{ convertDate(data.live_date) }}</div>
              <div class="text--primary">
                {{ data.live_house_name }}
              </div>
              <div>{{ data.title }}</div>
              <v-btn
                outlined
                tile
                :style="border_width"
                elevation="0"
                class="mt-3"
                small
                color="grey darken-1"
                ><span class="text-bold">detail</span></v-btn
              >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div v-if="topPage">
      <v-row class="paddingLiveList">
        <v-col
          :id="data.id + index"
          v-for="(data, index) in livelists"
          :key="'first-' + index"
          cols="12"
        >
          <v-card
            color="transparent"
            elevation="0"
            tile
            class="mx-2"
            v-if="index <= 1"
            @click.native="showMoreInformation(data.key)"
          >
            <v-divider class=""></v-divider>
            <v-card-title class="text-bold mb-0 pb-0 mt-4">{{
              convert_romaji(data.address)
            }}</v-card-title>
            <v-card-text class="mt-0 pt-0">
              <div class="text--primary">{{ convertDate(data.live_date) }}</div>
              <div class="text--primary">
                {{ data.live_house_name }}
              </div>
              <div>{{ data.title }}</div>
              <v-btn
                outlined
                tile
                :style="border_width"
                elevation="0"
                class="mt-3"
                small
                color="grey darken-1"
                ><span class="text-bold">detail</span></v-btn
              ></v-card-text
            >
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-row v-if="topPage" class="text-center mt-2">
      <v-col cols="12">
        <v-btn
          to="/live"
          outlined
          tile
          :style="border_width"
          elevation="0"
          color="grey darken-4"
          ><span class="text-bold">VIEW MORE</span></v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import prefectures from "../assets/json/prefectures.json";
export default {
  name: "Live",
  props: {
    topPage: { type: Boolean, default: false },
  },
  data: () => ({
    model: null,
    dayList: ["日", "月", "火", "水", "木", "金", "土"],
    dialog: false,
    slickOptions: {
      autoplay: true,
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 6,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 2,
          },
        },
      ],
    },
    prefectures: prefectures,
    border_width: "border-width: 2px;",
  }),
  computed: {
    cardWidth: function () {
      let val = 380;
      if (this.$vuetify.breakpoint.xs) {
        val = 220;
      }
      return val;
    },
    textSize: function () {
      let val = "text-subtitle-1";
      if (this.$vuetify.breakpoint.xs) {
        val = "text-body-1";
      }
      return val;
    },
    livelists: function () {
      let res = this.extractLiveList(this.$store.state.liveLists);
      if (this.topPage) {
        return res.slice(0, 5);
      } else {
        return res;
      }
    },
    title_margin: function () {
      let val = "ml-3";
      if (this.$vuetify.breakpoint.xs) {
        val = "ml-1";
      }
      return val;
    },
  },
  created: function () {
    if (this.livelists.length == 0) {
      this.getLiveList();
    }
    console.log(this.prefectures);
  },
  methods: {
    getLiveList() {
      this.axios
        .get(process.env.VUE_APP_API_URL + "live")
        .then((response) => {
          this.$store.dispatch("setLiveList", { liveLists: response.data });
        })
        .catch((e) => {
          alert(e);
        });
    },
    // 解禁日以降のもののみ抽出
    extractLiveList(liveList) {
      let res = [];
      dayjs.extend(isSameOrAfter);
      // 現在時刻を取得
      let now = dayjs(new Date());
      for (let i = 0; i < liveList.length; i++) {
        //情報解禁日を取得
        let info_time = dayjs(liveList[i]["info_time"]);
        //現在時刻が情報解禁日以降の場合、返却用配列に格納
        if (now.isAfter(info_time)) {
          res.push(liveList[i]);
        }
      }
      return res;
    },
    goGoogleMap(address) {
      let url = "'https://maps.google.co.jp/maps/search/'" + address;
      window.open(url, "_blank");
    },
    convertDate(date) {
      let dateObj = new Date(date);
      let dateYear = dateObj.getFullYear();
      let dateMonth = dateObj.getMonth() + 1;
      let dateDate = dateObj.getDate();
      let dateDay = dateObj.getDay();
      let newDate =
        dateYear +
        "." +
        dateMonth +
        "." +
        dateDate +
        "(" +
        this.dayList[dateDay] +
        ")";
      return newDate;
    },
    showMoreInformation(id) {
      this.$router.push({
        name: "liveDetail",
        params: { id: id },
      });
    },
    next() {
      this.$refs.slick.next();
    },
    prev() {
      this.$refs.slick.prev();
    },
    reInit() {
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    },
    convert_romaji(address) {
      for (let idx in this.prefectures) {
        if (address.includes(this.prefectures[idx].kanji)) {
          return this.prefectures[idx].romanized;
        }
      }
    },
  },
};
</script>
<style>
.paddingLiveList {
  padding: 0 5%;
}
</style>
