<!-- アルバム詳細情報 -->
<template>
  <v-container>
    <v-row class="ma-3">
      <v-col cols="12" :class="paddingNewsDetailList">
        <span :class="title_margin" class="text-size-xxl text-bold title-font"
          >NEWS</span
        >
      </v-col>
    </v-row>
    <v-row :class="paddingNewsDetailList">
      <v-col cols="12" class="">
        <div>
          <v-card-subtitle class="text-left">{{
            convertDate(newsLists["news_date"])
          }}</v-card-subtitle>
          <v-card-title>{{ newsLists["title"] }}</v-card-title>
          <v-card-text
            class="text-left overflow"
            style="white-space: pre-line"
            >{{ newsLists["detail"] }}</v-card-text
          >
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "NewsDetail",
  data: () => ({
    model: null,
  }),
  computed: {
    cardWidth: function () {
      let val = 380;
      if (this.$vuetify.breakpoint.xs) {
        val = 220;
      }
      return val;
    },
    newsLists: function () {
      var res = [];
      for (let i = 0; i < this.$store.state.newsLists.length; i++) {
        if (this.$store.state.newsLists[i]["title"] == this.$route.params.id) {
          res = this.$store.state.newsLists[i];
        }
      }
      return res;
    },
    paddingNewsDetailList: function () {
      let val = "paddingNewsDetailList";
      if (this.$vuetify.breakpoint.xs) {
        val = "mx-3";
      }
      return val;
    },
    title_margin: function () {
      let val = "ml-2";
      if (this.$vuetify.breakpoint.xs) {
        val = "ml-1";
      }
      return val;
    },
  },
  created: function () {
    if (this.newsLists.length == 0) {
      this.getLiveList();
    }
  },
  methods: {
    getLiveList() {
      this.axios
        .get(process.env.VUE_APP_API_URL + "news")
        .then((response) => {
          this.$store.dispatch("setNewsList", { newsLists: response.data });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    convertDate(date) {
      let dateObj = new Date(date);
      let dateYear = dateObj.getFullYear();
      let dateMonth = dateObj.getMonth() + 1;
      let dateDate = dateObj.getDate();
      let newDate = dateYear + "." + dateMonth + "." + dateDate;
      return newDate;
    },
    concertInt(track) {
      let result = track.substr(6);
      return result;
    },
  },
};
</script>
<style>
.paddingNewsDetailList {
  padding: 0 20%;
}
.color {
  color: white;
}
.overflow {
  display: inline-block;
  overflow-wrap: break-word;
  width: 100%; /* 追加 */
  max-width: 100%;
}
</style>
