<!-- ライブ情報 -->
<template>
  <v-container>
    <v-row class="ma-3">
      <v-col cols="12" :class="padding">
        <span :class="title_margin" class="text-size-xxl text-bold title-font"
          >LIVE</span
        >
      </v-col>
    </v-row>
    <div>
      <v-row>
        <v-col :class="padding" class="mx-3">
          <v-card-title class="text-size-xm word-break text-bold mb-2 pb-0">{{
            liveLists["title"]
          }}</v-card-title>
          <div
            v-if="this.isNull(liveLists['ticket_url'])"
            class="ml-4 px-0 mb-10"
          >
            <v-btn
              class="ml-0 mt-1"
              elevation="0"
              color="#616161"
              @click="openDialog"
              outlined
              width="60%"
              tile
              :href="liveLists['ticket_url']"
              target
              :style="border_width"
              ><span class="text-bold" v-if="!buttonFlg"
                >チケット予約(外部リンク)</span
              >
            </v-btn>
          </div>
          <div v-else class="ml-4 px-0 mb-10">
            <v-btn
              class="ml-0 mt-1"
              elevation="0"
              color="#616161"
              :disabled="buttonFlg"
              @click="openDialog"
              outlined
              width="50%"
              tile
              :style="border_width"
              ><span class="text-bold" v-if="!buttonFlg">チケット予約</span
              ><span v-else>予約終了</span></v-btn
            >
            <BookingTickets
              :dialogOpen="dialog"
              @is-close="dialog = $event"
              :liveDetailList="liveLists"
            ></BookingTickets>
          </div>
          <v-card-subtitle class="py-0 my-0 text-size-x text-bold">
            会場<v-btn
              icon
              x-small
              color="primary"
              @click="goGoogleMap(liveLists['address'])"
              class="py-0 mt-0 mb-1 ml-1"
              ><v-icon dark>fa-location-dot</v-icon>
            </v-btn>
          </v-card-subtitle>
          <v-card-subtitle class="pt-0 mt-0">
            {{ liveLists["live_house_name"] }}
          </v-card-subtitle>
          <v-card-subtitle class="py-0 my-0 text-size-x text-bold">
            公演日
          </v-card-subtitle>
          <v-card-subtitle class="text-left pt-0 mt-0">{{
            convertDate(liveLists["live_date"])
          }}</v-card-subtitle>
          <v-card-subtitle class="py-0 my-0 text-size-x text-bold">
            共演
          </v-card-subtitle>
          <v-card-text
            style="white-space: pre-line"
            class="pt-0 mt-0 text-left"
            >{{ liveLists["detail"] }}</v-card-text
          >
          <v-card-subtitle class="my-0 py-0 text-size-x text-bold">
            開場 / 開演
          </v-card-subtitle>
          <v-card-subtitle class="mt-0 pt-0">
            {{ time_format(liveLists["open_time"]) }} /
            {{ time_format(liveLists["start_time"]) }}
          </v-card-subtitle>
          <v-card-subtitle class="my-0 py-0 text-size-x text-bold">
            前売り料金
          </v-card-subtitle>
          <v-card-subtitle class="mt-0 pt-0">
            ¥{{ liveLists["adv_price"] }}(ドリンク代別)
          </v-card-subtitle>
          <v-card-subtitle class="my-0 py-0 text-size-x text-bold">
            当日料金
          </v-card-subtitle>
          <v-card-subtitle class="mt-0 pt-0">
            ¥{{ liveLists["door_price"] }}(ドリンク代別)
          </v-card-subtitle>
          <v-card-subtitle
            v-if="this.isNull(liveLists['cast_price'])"
            class="my-0 py-0 text-size-x text-bold"
          >
            配信料金
          </v-card-subtitle>
          <v-card-subtitle
            v-if="this.isNull(liveLists['cast_price'])"
            class="mt-0 pt-0"
          >
            ¥{{ liveLists["cast_price"] }}
          </v-card-subtitle>
          <v-card-subtitle
            v-if="this.isNull(liveLists['cast_url'])"
            class="my-0 py-0 text-size-x text-bold"
          >
            配信URL
          </v-card-subtitle>
          <div class="ml-4 px-0 mb-4">
            <a
              class="text-left mx-0 px-0 word-break linktext"
              :href="liveLists['cast_url']"
              >{{ liveLists["cast_url"] }}</a
            >
          </div>
          <v-card-subtitle class="my-0 py-0 text-size-x text-bold">
            詳細
          </v-card-subtitle>
          <div class="ml-4 px-0 mb-4">
            <a
              class="text-left mx-0 px-0 word-break linktext"
              :href="liveLists['url']"
              >{{ liveLists["url"] }}</a
            >
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div>
            <v-img
              contain
              max-width="300"
              :src="liveLists['image']"
              class="mx-auto my-5"
            ></v-img>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import BookingTickets from "../components/BookingTickets";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
export default {
  name: "HelloWorld",
  data: () => ({
    model: null,
    dayList: ["日", "月", "火", "水", "木", "金", "土"],
    dialog: false,
    ticketCount: 0,
    ticketList: [],
    border_width: "border-width: 2px;",
  }),
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BookingTickets,
  },
  computed: {
    cardWidth: function () {
      let val = 380;
      if (this.$vuetify.breakpoint.xs) {
        val = 220;
      }
      return val;
    },
    liveLists: function () {
      var res = [];
      for (let i = 0; i < this.$store.state.liveLists.length; i++) {
        if (this.$store.state.liveLists[i]["key"] == this.$route.params.id) {
          res = this.$store.state.liveLists[i];
        }
      }
      return res;
    },
    padding: function () {
      let val = "paddingLive";
      if (this.$vuetify.breakpoint.xs) {
        val = "";
      }
      return val;
    },
    buttonFlg: function () {
      dayjs.extend(isSameOrAfter);
      //ライブ開場時間を取得
      let open_time = dayjs(
        this.get_live_open(
          this.liveLists["live_date"],
          this.liveLists["open_time"]
        )
      );
      // 現在時刻を取得
      let now = dayjs(new Date());
      let flg = now.isAfter(open_time);
      return flg;
    },
    title_margin: function () {
      let val = "ml-4";
      if (this.$vuetify.breakpoint.xs) {
        val = "ml-1";
      }
      return val;
    },
  },
  created: function () {
    if (this.liveLists.length == 0) {
      this.getLiveList();
    }
  },
  mounted: function () {
    this.ticketCount = this.checkTicketCount(this.liveLists["key"]);
    console.log(this.liveLists);
  },
  methods: {
    getLiveList() {
      this.axios
        .get(process.env.VUE_APP_API_URL + "live")
        .then((response) => {
          this.$store.dispatch("setLiveList", { liveLists: response.data });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    goGoogleMap(address) {
      let url = "https://maps.google.co.jp/maps/search/";
      let address_url = url + address;
      window.open(address_url, "_blank");
    },
    convertDate(date) {
      let dateObj = new Date(date);
      let dateYear = dateObj.getFullYear();
      let dateMonth = dateObj.getMonth() + 1;
      let dateDate = dateObj.getDate();
      let dateDay = dateObj.getDay();
      let newDate =
        dateYear +
        "年" +
        dateMonth +
        "月" +
        dateDate +
        "日(" +
        this.dayList[dateDay] +
        ")";
      return newDate;
    },
    openDialog() {
      this.dialog = !this.dialog;
    },
    time_format(time) {
      let res = time.slice(0, 5);
      return res;
    },
    get_live_open(date, time) {
      let dateObj = new Date(date);
      let dateYear = dateObj.getFullYear();
      let dateMonth = dateObj.getMonth() + 1;
      let dateDate = dateObj.getDate();
      let liveDate = dateYear + "-" + dateMonth + "-" + dateDate + "T" + time;
      return liveDate;
    },
    isNull(val) {
      if (val == null) {
        return false;
      }
      if (val == "") {
        return false;
      }
      return true;
    },
    checkTicketCount(key) {
      let that = this;
      this.axios
        .get(process.env.VUE_APP_API_URL + "ticket")
        .then((response) => {
          let live = response.data;
          for (let i = 0; i < live.length; i++) {
            if (live[i]["live_id"] == key) {
              that.ticketList.push(live[i]);
            }
          }
          if (that.ticketList.length != 0) {
            return that.ticketList.length;
          } else {
            return 0;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
<style>
.paddingLive {
  padding: 0 20%;
}
.linktext {
  color: black !important;
  font-size: 90%;
  letter-spacing: 0.03em;
}
</style>
