<template>
  <div>
    <NewsList class="mb-12" :topPage="true" />
    <LiveList class="mb-12 pb-5" :topPage="true" />
    <MusicList class="mb-12 pb-5" :topPage="true" />
    <MovieList class="mb-12 pb-12" :topPage="true" />
  </div>
</template>

<script>
import LiveList from "../components/Live";
import MusicList from "../components/Music";
import NewsList from "../components/News";
import MovieList from "../components/Movie";
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    LiveList,
    MusicList,
    NewsList,
    MovieList,
  },
  name: "HelloWorld",

  data: () => ({
    photoUrl: "",
    model: null,
    dayList: ["日", "月", "火", "水", "木", "金", "土"],
    isActiveNews: false,
    isActiveLive: false,
  }),
  created: function () {
    if (this.artistPhotoUrl == "") {
      this.getAritstPhoto();
    }
  },
  computed: {
    cardHeight: function () {
      let val = 750;
      if (this.$vuetify.breakpoint.xs) {
        val = 300;
      }
      return val;
    },
    artistPhotoUrl: function () {
      return this.$store.state.artistPhotoUrl;
    },
  },
  methods: {
    getAritstPhoto() {
      this.axios
        .get(process.env.VUE_APP_API_URL + "photo")
        .then((response) => {
          this.$store.dispatch("setArtistPhotoUrl", {
            artistPhotoUrl: response.data[0]["image"],
          });
        })
        .catch((e) => {
          alert(e);
        });
    },
    goGoogleMap(address) {
      let url = "'https://maps.google.co.jp/maps/search/'" + address;
      window.open(url, "_blank");
    },
    convertDate(date) {
      let dateObj = new Date(date);
      let dateYear = dateObj.getFullYear();
      let dateMonth = dateObj.getMonth() + 1;
      let dateDate = dateObj.getDate();
      let dateDay = dateObj.getDay();
      let newDate =
        dateYear +
        "年" +
        dateMonth +
        "月" +
        dateDate +
        "日(" +
        this.dayList[dateDay] +
        ")";
      return newDate;
    },
  },
};
</script>
<style>
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
  left: 20%;
  top: 45%;
}
</style>
