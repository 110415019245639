<!-- チケット詳細情報 -->
<template>
  <v-container>
    <v-row class="text-center ma-3">
      <v-col cols="12">
        <span class="text-size-xl">チケット詳細</span>
      </v-col>
    </v-row>
    <v-row class="text-center ma-3">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="ticketLists"
          :items-per-page="5"
          class="elevation-1"
        ></v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "TicketDetail",
  data: () => ({
    headers: [
      {
        text: "名前",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "メールアドレス", value: "email_address" },
      { text: "チケット枚数", value: "tickets" },
      { text: "ライブタイトル", value: "title" },
      { text: "ライブ日", value: "live_date" },
      { text: "ライブハウス", value: "live_house_name" },
    ],
  }),
  computed: {
    cardWidth: function () {
      let val = 380;
      if (this.$vuetify.breakpoint.xs) {
        val = 220;
      }
      return val;
    },
    ticketLists: function () {
      return this.$store.state.ticketLists;
    },
  },
  created: function () {
    if (this.ticketLists.length == 0) {
      this.getTicketList();
    }
  },
  methods: {
    getTicketList() {
      this.axios
        .get(process.env.VUE_APP_API_URL + "ticket")
        .then((response) => {
          this.$store.dispatch("setTicketList", { ticketLists: response.data });
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
<style>
.padding {
  padding: 0 10%;
}
.color {
  color: white;
}
</style>
