<template>
  <MovieList />
</template>

<script>
import MovieList from "../components/Movie";

export default {
  name: "movie",

  components: {
    // eslint-disable-next-line vue/no-unused-components
    MovieList,
  },
};
</script>
