<template>
  <LiveList />
</template>

<script>
import LiveList from "../components/Live";

export default {
  name: "live",

  components: {
    // eslint-disable-next-line vue/no-unused-components
    LiveList,
  },
};
</script>
