import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueAxios from "vue-axios";
import "./assets/sass/style.scss";
import "./assets/style/common.css"; // ここを追加
import LottieAnimation from "lottie-web-vue";

Vue.use(LottieAnimation); // add lottie-animation to your global scope
Vue.use(VueAxios, axios); //追記

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
