<!-- ライブ情報 -->
<template>
  <v-container>
    <v-row class="ma-3">
      <v-col cols="12">
        <span :class="title_margin" class="text-size-xxl text-bold title-font"
          >NEWS</span
        >
      </v-col>
    </v-row>
    <div v-if="!topPage">
      <v-row class="mx-1">
        <v-col
          :id="index"
          v-for="(data, index) in newsLists"
          :key="index"
          cols="12"
        >
          <v-card
            color="transparent"
            elevation="0"
            tile
            :id="index"
            class="mx-2"
            @click.native="showMoreInformation(data.title)"
          >
            <v-divider class=""></v-divider>
            <v-card-subtitle class="mb-0 pb-0 mt-4" :class="padding">{{
              convertDate(data.news_date)
            }}</v-card-subtitle>
            <v-card-title class="mt-0 pt-0" :class="textSize">{{
              data.title
            }}</v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div v-if="topPage" class="mx-2">
      <v-row>
        <v-col
          :id="index"
          v-for="(data, index) in newsLists"
          :key="index"
          cols="12"
        >
          <v-card
            color="transparent"
            elevation="0"
            tile
            :id="index"
            class="mx-2"
            v-if="index <= 1"
            @click.native="showMoreInformation(data.title)"
          >
            <v-divider class=""></v-divider>
            <v-card-subtitle class="mb-0 pb-0 mt-4" :class="padding">{{
              convertDate(data.news_date)
            }}</v-card-subtitle>
            <v-card-title class="mt-0 pt-0" :class="textSize">{{
              data.title
            }}</v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-row v-if="topPage" class="text-center ma-3">
      <v-col cols="12">
        <v-btn
          to="/news"
          outlined
          tile
          :style="border_width"
          elevation="0"
          color="grey darken-4"
          ><span class="text-bold">VIEW MORE</span></v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "DISCOGRAPHY",
  props: {
    topPage: { type: Boolean, default: false },
  },
  data: () => ({
    model: null,
    dayList: ["日", "月", "火", "水", "木", "金", "土"],
    dialog: false,
    border_width: "border-width: 2px;",
  }),
  computed: {
    textSize: function () {
      let val = "text-subtitle-1";
      if (this.$vuetify.breakpoint.xs) {
        val = "text-body-1";
      }
      return val;
    },
    padding: function () {
      let val = "pt-5";
      if (this.$vuetify.breakpoint.xs) {
        val = "pt-4";
      }
      return val;
    },
    paddingList: function () {
      let val = "px-12";
      if (this.$vuetify.breakpoint.xs) {
        val = "blank";
      }
      return val;
    },
    cardWidth: function () {
      let val = 380;
      if (this.$vuetify.breakpoint.xs) {
        val = 220;
      }
      return val;
    },
    newsLists: function () {
      return this.$store.state.newsLists;
    },
    title_margin: function () {
      let val = "ml-3";
      if (this.$vuetify.breakpoint.xs) {
        val = "ml-1";
      }
      return val;
    },
  },
  created: function () {
    if (this.newsLists.length == 0) {
      this.getNewsList();
    }
  },
  methods: {
    getNewsList() {
      this.axios
        .get(process.env.VUE_APP_API_URL + "news")
        .then((response) => {
          this.$store.dispatch("setNewsList", { newsLists: response.data });
        })
        .catch((e) => {
          alert(e);
        });
    },
    convertDate(date) {
      let dateObj = new Date(date);
      let dateYear = dateObj.getFullYear();
      let dateMonth = dateObj.getMonth() + 1;
      let dateDate = dateObj.getDate();
      let newDate = dateYear + "." + dateMonth + "." + dateDate;
      return newDate;
    },
    test() {
      return;
    },
    showMoreInformation(id) {
      this.$router.push({
        name: "newsDetail",
        params: { id: id },
      });
    },
  },
};
</script>
<style>
.paddingNewsTopList {
  padding: 0 4%;
}
</style>
