<!-- ライブ情報 -->
<template>
  <v-container>
    <v-row class="mt-3">
      <v-col cols="12">
        <span class="text-size-xxl ml-2 text-bold title-font">PROFILE</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div>
          <v-img contain :src="artistPhotoUrl" class="mx-auto my-5"></v-img>
        </div>
      </v-col>
    </v-row>
    <v-row class="d-flex">
      <v-col
        :id="data.id + index"
        v-for="(data, index) in memberLists"
        :key="index"
        class="mx-3 d-flex mx-auto"
        :cols="colsMobile"
        ><v-card color="transparent" class="mx-auto" elevation="0">
          <v-card-text>
            <v-avatar size="70" class="mb-2">
              <v-img position="50% 10%" alt="user" :src="data.image" />
            </v-avatar>
            <div>{{ data.part }}</div>
            <p class="text-bold text-size-xl">{{ data.name }}</p>
            <p>{{ data.name_english }}</p>
            <div class="text--primary">
              {{ convertDate(data.birth_date) }}生
            </div>
            <div v-if="data.spport == true" class="text--primary">
              サポートメンバー
            </div>
            <v-btn
              v-if="data.twiiter_url"
              icon
              :href="data.twiiter_url"
              target="_blank"
              class="mr-2 ml-n1 mt-3"
              ><v-icon>fa-brands fa-twitter</v-icon></v-btn
            >
            <v-btn
              v-if="data.instagram_url"
              icon
              :href="data.instagram_url"
              target="_blank"
              class="mr-2 mt-3"
              ><v-icon>fa-brands fa-instagram</v-icon></v-btn
            >
          </v-card-text>
        </v-card></v-col
      >
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "profile",
  data: () => ({
    dayList: ["日", "月", "火", "水", "木", "金", "土"],
  }),
  computed: {
    memberLists: function () {
      return this.$store.state.memberLists;
    },
    artistPhotoUrl: function () {
      return this.$store.state.artistPhotoUrl;
    },
    colsMobile: function () {
      let val = 3;
      if (this.$vuetify.breakpoint.xs) {
        val = 6;
      }
      return val;
    },
    title_margin: function () {
      let val = "";
      if (this.$vuetify.breakpoint.xs) {
        val = "ml-1";
      }
      return val;
    },
  },
  created: function () {
    if (this.memberLists.length == 0) {
      this.getMemberList();
    }
  },
  mounted() {},
  methods: {
    getAritstPhoto() {
      this.axios
        .get(process.env.VUE_APP_API_URL + "photo")
        .then((response) => {
          this.$store.dispatch("setArtistPhotoUrl", {
            artistPhotoUrl: response.data[0]["image"],
          });
        })
        .catch((e) => {
          alert(e);
        });
    },
    getMemberList() {
      this.axios
        .get(process.env.VUE_APP_API_URL + "member")
        .then((response) => {
          this.$store.dispatch("setMemberList", { memberLists: response.data });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    convertDate(date) {
      let dateObj = new Date(date);
      let dateYear = dateObj.getFullYear();
      let dateMonth = dateObj.getMonth() + 1;
      let dateDate = dateObj.getDate();
      let newDate = dateYear + "年" + dateMonth + "月" + dateDate + "日";
      return newDate;
    },
  },
};
</script>
<style>
.paddingLive {
  padding: 0 20%;
}
</style>
